* {
  margin: 0;
  padding: 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #bbe1fa;
}
